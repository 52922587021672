import React, { useState, useEffect, useCallback } from 'react';
import useAuthentication from '@belong/providers/authentication';
import useUser from '@belong/providers/user';
import { SERVICE_URLS } from '@belong/url-utils';
import { ActionStrip, Section } from '@belong/ui-components';
import { ISectionActionStrip } from './model';

const SectionActionStrip: React.FC<ISectionActionStrip> = ({
  id,
  identifier,
  heading,
  link,
  sectionStyles,
  headingLevel
}: ISectionActionStrip) => {
  const { isLoggedIn } = useAuthentication();
  const { user } = useUser();
  const [throttledLink, updateLink] = useState(link);
  const isActivateSim = throttledLink.label?.toLowerCase().includes('activate sim');

  const redirectActivateSim = useCallback(
    (linkKey, url) => {
      const { label } = link;

      if (label.toLowerCase().trim() === linkKey.toLowerCase().trim()) {
        updateLink({
          ...link,
          href: url,
          asUrl: undefined
        });
      }
    },
    [link]
  );

  const patchCTALink = useCallback(() => {
    if (isActivateSim) {
      if (isLoggedIn && user) {
        updateLink({
          ...link,
          href: SERVICE_URLS.ACTIVE_SIM,
          asUrl: undefined
        });
      }
    }
  }, [isActivateSim, isLoggedIn, user, link]);

  useEffect(() => {
    const windowScope = window as any;

    if (isActivateSim && !isLoggedIn) {
      windowScope.BELONG = windowScope.BELONG || {};
      windowScope.BELONG.redirectActivateSim = redirectActivateSim; // Function to be called by Adobe Target
    }

    return () => {
      if (windowScope.BELONG) {
        delete windowScope.BELONG.redirectActivateSim;
      }
    };
  }, [isLoggedIn, isActivateSim, patchCTALink, redirectActivateSim]);

  return (
    <Section
      data-contentid={id}
      data-testid="section-action-strip"
      data-identifier={identifier}
      isFullWidth
      isPadded={false}
      {...sectionStyles}
    >
      <ActionStrip heading={heading} link={throttledLink} headingLevel={headingLevel} />
    </Section>
  );
};

export default SectionActionStrip;
